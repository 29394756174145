import withRoot from "../utils/withRoot";
import React from "react";
 

class NotFoundPage extends React.Component {
  render() {
    return (
      
        <p>Hey! You just hit a page that doesn't exist...</p>
       
    );
  }
}

export default withRoot(NotFoundPage);
